import { format, isBefore } from 'date-fns'
import { th } from 'date-fns/locale'

export function isIE() {
  return new RegExp('MSIE|Trident').test(navigator.userAgent)
}

export function isAddress(input: string) {
  return /^0x([A-Fa-f0-9]{32,64})$/g.test(input)
}

export function isExpired(date: Date | string | number) {
  return isBefore(new Date(date), Date.now())
}

export function generateId(radix: number = 16) {
  return Math.random().toString(radix).slice(2)
}

export function scrollOff(input: boolean = true) {
  document.body.style.overflowY = input ? 'hidden' : 'auto'
}

export function toCapitalize(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1)
}

export function omit<T = any>(obj: T, key: string): any {
  return Object.fromEntries(Object.entries(obj as any).filter((r) => r[0] !== key))
}

export function dateLocale(date: Date | number, shape: string) {
  return format(date, shape, { locale: th })
}

/**
 * @param delay Add a delay of milliseconds (default: 1 second)
 */
export async function delay(delay: number = 1e3) {
  return await new Promise((resolve) => setTimeout(resolve, delay))
}

/**
 * Convert to format: phone, price.
 *
 * @param input Number, String
 * @param matcher RegExp
 * @param separator String
 */
export function numberFormat(input: string | number, matcher?: RegExp, separator: string = '-') {
  if (typeof input === 'string' && matcher) {
    const matched = input.match(matcher)
    return matched ? matched.slice(1).join(separator) : input
  }

  if (typeof input === 'number' && !matcher) {
    const intl = new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' })
    return intl.format(input)
  }

  return input
}

/**
 * Convert address to short string.
 *
 * @param {string} address
 */
export function shortAddress(address?: string | null): string {
  if (address) {
    return `${address.slice(0, 7)}...${address.slice(address.length - 5)}`
  } else {
    return '...'
  }
}

export function accountName(str: string = '') {
  return isAddress(str) ? shortAddress(str) : str?.split('@')[0]
}
