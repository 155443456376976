import { decodeJwt } from 'jose'
import { ZodError } from 'zod'
import { notice } from '@/utils/addon'

export async function getAuthorize(headers: Headers) {
  const authorization = headers.get('Authorization')

  if (authorization) {
    const accessToken = authorization.replace('Bearer', '').trim()
    const payload = decodeJwt(accessToken)

    return {
      uid: payload.sub!,
      email: payload.email
    }
  }

  return void 0
}

export function tryCatch(message: string, error: any | Error) {
  console.error(message, error)

  if (error?.response?.data) {
    let { statusCode, error: title, message: content } = error.response.data

    if (content instanceof Array) {
      content = content.map((r) => `<p>- ${r}</p>`).join('')
    } else {
      content = `<p>- ${content}</p>`
    }

    notice.error(content, { title: `${statusCode}: ${title}`, duration: 6 })
  } else {
    notice.error(error.message, { title: error.code, duration: 6 })
  }
}

export function apiTryCatch(error: any | Error, statusCode: number = 422) {
  let message: string | string[] = error?.message || 'Something went wrong!'

  if (error instanceof ZodError && !error.isEmpty) {
    const { errors: err } = error as ZodError
    message = err.map((r) => `This field "${r.path[0]}": ${r.message.toLowerCase()}`)
  } else {
    console.error(error)
  }

  return Response.json(
    {
      statusCode,
      message
    },
    {
      status: statusCode
    }
  )
}
