import { configs } from '@/constants'
import { cookie } from '@/utils/storage'
import type { FormSignIn } from '@/types/form'

import axios from '../axios'
import { tryCatch } from '../helpers'
import { addDays } from 'date-fns'

export class AuthService {
  static async signIn(data: FormSignIn) {
    try {
      const response = await axios.post<XHRLogin>('auth/sign-in', data)
      if (response.data) {
        const { accessToken, expiredAt } = response.data

        cookie.set(configs.APP_AUTH_ACCESS, accessToken, {
          expires: addDays(new Date(), 7)
        })

        return true
      }
    } catch (error: any) {
      tryCatch('`AuthService.login`', error)
    }
  }

  static signOut(cb?: Function): void {
    cookie.remove(configs.APP_AUTH_ACCESS)
    cookie.remove(configs.APP_AUTH_REFRESH)

    if (cb) cb()
  }
}
