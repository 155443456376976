export * as configs from './configs'
export { Fruit } from './fruit'

export enum Theme {
  DEFAULT = 'default',
  LIGHT = 'light',
  DARK = 'dark'
}

export enum DialogName {
  SYSTEM_ALERT = '@DIALOG:SYSTEM_ALERT'
}

export enum ModalName {}

export enum NoticeName {}
