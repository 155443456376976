import { isBefore, subSeconds } from 'date-fns'

import { delay } from '@/utils'
import type { IRecord } from '@/types'

import axios from '../axios'
import { tryCatch } from '../helpers'

export class CommonService {
  static async getHealthz() {
    try {
      const response = await axios.get<IRecord>(`/records/8?type=IMSG-HEALTHZ&ts=${Date.now()}`)
      await delay()

      return {
        service: true,
        paymentSystem: response.data && isBefore(subSeconds(Date.now(), 90), new Date(response.data.value))
      }
    } catch (error) {
      tryCatch('`CommonService.getHealthz`', error)
    }
  }
}
