import type { ITrans, ITransQuery } from '@/types'

import axios from '../axios'
import { tryCatch } from '../helpers'

export class TransService {
  static async getAll(query: ITransQuery) {
    try {
      const response = await axios.get<Paginate<ITrans>>('/trans', { params: query })
      return response.data
    } catch (error) {
      tryCatch('`TransService.getAll`', error)
    }
  }

  static async approve(id: number, amount: number) {
    try {
      const response = await axios.patch(`/tasks/manual-approve?id=${id}&amount=${amount}`)
      return response.data
    } catch (error) {
      tryCatch('`TransService.approve`', error)
    }
  }
}
