import type { IRecord, IRecordQuery } from '@/types'
import type { FormUpdateRecord } from '@/types/form'

import axios from '../axios'
import { tryCatch } from '../helpers'

export class RecordService {
  static async getAll(query: IRecordQuery) {
    try {
      const response = await axios.get<Paginate<IRecord>>(`/records`, { params: query })
      return response.data
    } catch (error) {
      tryCatch('`RecordService.getAll`', error)
    }
  }

  static async getOne(id: number) {
    try {
      const response = await axios.get<IRecord>(`/records/${id}`)
      return response.data
    } catch (error) {
      tryCatch('`RecordService.getAll`', error)
    }
  }

  static async update(id: number, formData: FormUpdateRecord) {
    try {
      const response = await axios.patch<Response<void>>(`/records/${id}`, formData)
      return response.data
    } catch (error) {
      tryCatch('`RecordService.update`', error)
    }
  }
}
